:root {
  --dark-navy: #020c1b;
  --navy: #000000;
  --light-navy: #181a1b;
  --lightest-navy: #2d2d30;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #979797;
  --green-tint: rgba(100, 255, 218, 0.1);
}
